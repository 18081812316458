let resolveFunc
const state = new Promise((resolve, reject) => {
  resolveFunc = resolve
})

export const getState = () => {
  return state
}

export const setLoaded = () => {
  resolveFunc()
}
