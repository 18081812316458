import * as React from "react"
import { HeadProps, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { setLoaded, getState } from "../basic/paypalScriptLoadingState"
import {
  Container,
  Box,
  Space,
  Heading,
  Text,
  HomepageImage,
  Kicker,
} from "../components/ui"
import * as styles from "./blog-post.css"
import { HeadingLine } from "../basic/HeadingLine"
import Head from "../components/head"

export interface BlogAuthor {
  id: string
  name: string
  avatar: HomepageImage
}

export interface BlogPost {
  id: string
  slug: string
  title: string
  excerpt: string
  categories?: { nodes: { name: string }[] }
  date: string
  html: string
  image: HomepageImage
  author: BlogAuthor
  next?: BlogPost
  previous?: BlogPost
}

function onLoad() {
  setLoaded()
}

const payPalConfig = {
  env: "production",
  hosted_button_id: "47ZDXRBZWVVZW",
  image: {
    src: "https://www.paypalobjects.com/de_DE/DE/i/btn/btn_donate_LG.gif",
    alt: "Spenden mit dem PayPal-Button",
    title: "PayPal - The safer, easier way to pay online!",
  },
}

export default function BlogPost(props) {
  const post = props.data.wpPost

  const formattedDate = new Date(post.date).toLocaleString("de", {
    day: "numeric",
    month: "long",
    year: "numeric",
  })

  React.useEffect(() => {
    getState().then(() => {
      window.PayPal?.Donation?.Button(payPalConfig).render("#donate-button")
      window.PayPal?.Donation?.Button(payPalConfig).render("#donate-button2")
    })
  }, [])

  React.useEffect(() => {
    if (!document.getElementById("donate-basic")) {
      const scriptElement = document.createElement("script")
      scriptElement.setAttribute(
        "src",
        "https://www.paypalobjects.com/donate/sdk/donate-sdk.js"
      )
      scriptElement.setAttribute("id", "donate-basic")
      scriptElement.onload = onLoad
      document.head.appendChild(scriptElement)
    }
  }, [])

  const categoryNames = post.categories.nodes.map((cat) => cat.name) || []

  return (
    <>
      <Head {...post} description={post.excerpt} />
      <Container>
        <Box paddingY={5}>
          <Kicker center>{categoryNames.join(" - ")}</Kicker>
          <Heading as="h1" center>
            {post.title}
          </Heading>
          <HeadingLine />
          <Space size={4} />
          <Text center style={{ fontSize: "18px" }}>{`${formattedDate} ${
            post.author ? post.author.name : ""
          }`}</Text>
          <div
            className={styles.blogPost}
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
          />
        </Box>
      </Container>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      id
      title
      content
      categories {
        nodes {
          name
        }
      }
      slug
      date(formatString: "MMMM DD, YYYY")
    }
  }
`
